<template>
  <div id="app">
      <router-view></router-view>
      <Footer/>
  </div>
</template>

<script>
import Footer from './common/Footer'
export default {
  name: 'app',
  components: {
    Footer
  },
  data() {
    return {
      status:0
    };
  },
  methods: {
    getBaseInfo(){
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res=>{
        if(!localStorage.getItem('token')){
          this.$router.push({path:'/Login'})
        }
        this.$store.commit('setBaseInfoValue', res.data);
      })
    }

  },
  created(){
    this.getBaseInfo();
  }
}
</script>

<style>
body .van-toast {
  font-size: 38px;
  padding: 30px;
  line-height: 50px;
  width: 230px;
}
body .van-toast .van-toast__icon {
  font-size: 50px;
}
*, :after, :before {
  box-sizing: border-box;
}



/* 横向排列 */
.rows {
  display: flex;
  flex-direction: row;
}

/* 纵向排列 */
.cols {
  display: flex;
  flex-direction: column;
}

/* 平铺 */
.box {
  display: flex;
  justify-content: space-between;
}
.box-h {
  display: flex;
  justify-content: space-between;
}

.box-v {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 横向排列 */
.rows-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.rows-wrap-item{
  width: 22.5vw;
//width: 300px;
//padding: 10px 0px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
//font-size: 18px;
}

.rows-wrap-item-50{
  width: 45vw;
}

.rows-wrap-item-100{
  width: 90vw;
}


/* 文本垂直居中 */
.text-center-v {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.text-center-h {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.text-center-hv {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
