<template>
  <div class="container page">
    <van-nav-bar title="充值" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="$router.push({path:'/RechargeRecord'})">充值记录</span>
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span>充值金额 (元)</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType">¥</span>
            <van-field v-model="withdraw_money" type="number" placeholder=""/>
          </div>
          <span class="all" @click="allMoeny()"></span>
        </div>

        <span>充值备注</span>
        <div class="money">
          <div class="moneyNumber" >
            <span class=""></span>
            <van-field v-model="remark" type="text" placeholder="请输入备注" style="font-size: 18px"/>
          </div>
          <span class="all" @click="allMoeny()"></span>
        </div>


        <div class="information">
<!--          <div class="description">-->
<!--            <van-popover v-model="showPopover" trigger="click">-->
<!--              <div class="popover-body" style="padding: 10px;">-->
<!--                <p>1.单笔限额：最低{{this.withdrawRole.min}}元，最高{{this.withdrawRole.max}}元</p>-->
<!--                <p>2.提现次数：一天最高提现{{this.withdrawRole.num}}次</p>-->
<!--                <p>3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账</p></div>-->
<!--              <template #reference @click="withdrawInfo()">-->
<!--                <van-icon name="info-o" />-->
<!--                限额说明-->
<!--              </template>-->
<!--            </van-popover>-->
<!--          </div>-->

          <div class="balance">
            <span>余额：</span>
            <span class="number">{{ this.userInfo.money }}元</span>
          </div>
        </div>
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()">立即充值</van-button>

      <!--      这里是弹出的内容-->
      <!--      <van-cell is-link @click="showPopup">展示弹出层</van-cell>-->
      <van-popup v-model="show" style="width: 333px;height: 550px;">

        <div class="wrapper">
          <div class="item">
            <p class="title">请输入资金密码</p>
            <van-password-input
                :value="opw"
                :length="4"
                :gutter="10"
                :focused="oshowKeyboard"
                @focus="oshowKeyboard = true;tshowKeyboard = false;"
            />
            <van-number-keyboard
                v-model="opw"
                :show="oshowKeyboard"
                @input ="opw.length !== 3 ? oshowKeyboard = true  : tshowKeyboard=true;"
                @blur="oshowKeyboard = false"
            />
          </div>

          <van-button class="sub-btn" type="default" @click="setPayPassword()">提交</van-button>
        </div>


      </van-popup>


    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money:"",
      userInfo:{},
      withdrawRole:{},
      remark:"",

      // 弹出层
      show: false,
      opw: '',
      tpw: '',
      oshowKeyboard: true,
      tshowKeyboard: false,

    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserWithdrawRole(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res=>{
        if(res.code === 200){
          this.withdrawRole = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    allMoeny(){
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw(){
      if(this.withdraw_money <= 0){
        this.$toast("请填写正确的金额");
        return false;
      }else {
        this.onSubmitRecharge();

        //弹出提现金额
        // this.showPopup();

        // this.$http({
        //   method: 'post',
        //   data:{money:this.withdraw_money},
        //   url: 'user_set_withdraw'
        // }).then(res=>{
        //   if(res.code === 200){
        //     location. reload()
        //     this.$toast(res.msg);
        //     this.getUserInfo();
        //     this.getUserWithdrawRole();
        //   }else if(res.code ===401){
        //     this.$toast(res.msg);
        //   }
        // })
      }
    },
    withdrawInfo(){
      this.showPopover = true;
    },
    showPopup() {
      this.show = true;
    },

    //这里输入密码提交了
    setPayPassword() {
      this.oshowKeyboard = false;
      this.tshowKeyboard = false;
      if(this.opw.length !== 4){
        this.oshowKeyboard = true;
        this.$toast("请填写完整");
        return false;
      }

      if(this.withdraw_money <= 0){
        this.$toast("请填写正确的金额");
        return false;
      }else {
        this.$http({
          method: 'post',
          data:{money:this.withdraw_money, opw: this.opw, remark: this.remark},
          url: 'user_set_recharge'
        }).then(res=>{
          if(res.code === 200){
            location. reload()
            this.$toast(res.msg);
            this.getUserInfo();
            this.getUserWithdrawRole();
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      }



    },

    onSubmitRecharge() {

      if(this.withdraw_money <= 0){
        this.$toast("请填写正确的金额");
        return false;
      }else {
        this.$http({
          method: 'post',
          data:{money:this.withdraw_money, opw: this.opw, remark: this.remark},
          url: 'user_set_recharge'
        }).then(res=>{
          if(res.code === 200){
            location. reload()
            this.$toast(res.msg);
            this.getUserInfo();
            this.getUserWithdrawRole();
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      }



    },

  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserWithdrawRole();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.container .main{
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}
.container .main .withdrawMoney{
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #fff;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}
.container .main .withdrawMoney .money .moneyNumber{
  font-size: 50px;
  display: flex;
  flex-direction: row;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money .all{
  color: #d10404;
}
.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0!important;
}
.container .main .withdrawMoney .information{
  padding-bottom: 30px;
}
.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}
.container .main .withdrawMoney span{
  padding: 10px 0;
}
.container .main .withdrawMoney .information .balance .number{
  color: #d10404;
}
.withdraw_btn {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}

//这里复制字 setPayPassword 样式
.container .wrapper{
  height: 100%;
  //overflow-y: auto;
  background-color: #fff;
  //-webkit-overflow-scrolling: touch;
}
.container .wrapper .item .title{
  margin: 40px 0;
  line-height: 20px;
  font-size: 30px;
  font-weight: 500;
  color: #000;
  text-align: center;
}
::v-deep .van-password-input{
  width: 80%;
  height: 150px;
  margin: 0 auto;

}
::v-deep .van-password-input__security li {
  font-size: 30px;
  line-height: 30;
  background-color: #ebedf0;
}
::v-deep .van-password-input__security {
  height: 130px;
}
::v-deep .van-password-input .van-password-input__security .van-password-input__item {
  height: 100%;
  border: 0;
  text-align: center;
  border-radius: 30px;
}
.van-password-input__security i {
  width: 25px;
  height: 25px;
}
::v-deep .van-key {
  height: 100px;
  font-size: 55px;
  line-height: 20px;
  border-radius: 20px;
}
::v-deep .van-number-keyboard {
  z-index:100;
  width: 100%;
  padding-bottom: 30px;
  background-color: #f2f3f5;
}
::v-deep .van-key__collapse-icon {
  width: 50px;
  height: 50px;
}
::v-deep .van-key__delete-icon {
  width: 50px;
  height: 50px;
}
.container .wrapper .sub-btn{
  margin: 110px 0 0 10%;
  height: 100px;
  width: 80%;
  font-size: 35px;
  border-radius: 50px;
  color: #fff;
  background: linear-gradient(
      270deg,#e6c3a1,#7e5678);
}
</style>
